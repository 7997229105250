import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image/compat"
// Hooks
import { useImageSrc } from "../hooks/useImageSrc"
// Components
import { Layout } from "../components/Layout"
import {RelativeServices} from "../components/RelativeServices/RelativeServices";
// Styles
import "../styles/selectedService.sass"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        text
        featuredImage {
          absolutePath
          childImageSharp {
            fluid(maxWidth: 1440, quality: 100) {
              base64
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
        }
      }
      html
    }
  }
`

const SelectedService = ({
  data: {
    markdownRemark: { frontmatter, html },
  },
}) => {
  const { src, srcSet } = useImageSrc()
  const fluid = frontmatter.featuredImage.childImageSharp.fluid

  return (
    <Layout
      title={`${frontmatter.title} | Студия красоты "Lab Beauty" | г. Бровары`}
      description={frontmatter.text}>
      <section className="service">
        <div className="img">
          {src && fluid && (
            <GatsbyImage
              style={{ width: "100%", height: "100%" }}
              placeholder={{ fallback: fluid.base64 }}
              images={{
                fallback: {
                  src: fluid.src,
                  srcSet: fluid.srcSet,
                },
                sources: [
                  {
                    src: fluid[src],
                    srcSet: fluid[srcSet],
                  },
                ],
              }}
              layout="responsive"
              alt="my gatsby image"
            />
          )}
        </div>

        <div className="content">
          <div className="text">
            <h1>{frontmatter.title}</h1>
          </div>

          <div dangerouslySetInnerHTML={{ __html: html }} />
          <hr />
        </div>
      </section>
      <RelativeServices currentService={frontmatter.title} />
    </Layout>
  )
}

export default SelectedService
